
import { 
  IonPage, 
  IonHeader, 
  IonToolbar, 
  IonTitle, 
  IonContent,
  IonButtons,
  IonButton,
  IonBackButton,
  IonIcon,
  IonList,
  IonItem,
  IonItemDivider,
  IonLabel,
  IonSpinner,
  IonReorder,
  IonReorderGroup,
  IonText,
  IonItemSliding,
  IonItemOptions,
  IonItemOption,


  popoverController,
  onIonViewDidEnter,
 } from '@ionic/vue';

import { 
  checkmark, 
  addCircleOutline,
  ellipseOutline,
  checkmarkCircleOutline,
  checkmarkCircle,
  create,
  shuffle,
  close,
  ellipsisVertical,
  trashOutline,
  chevronBack,
}  from 'ionicons/icons';

import { defineComponent, ref, computed } from '@vue/runtime-core';
import { useRouter, useRoute } from 'vue-router';
import { ItemOrder, apiEndpoints, TaskGroup, ApiError, isApiError } from '@/lib/api-endpoints';
import t from '@/translation'
import { useStore } from '@/store';
import Popover from '@/components/ToDoPopover.vue';
import * as yup from 'yup';
import { useForm } from 'vee-validate';
import MInput from '@/components/MInput.vue';
import { showModal } from '@/lib/modal';

export default  defineComponent({
  name: 'WeddingInfo',
  components: { 
    IonPage, 
    IonHeader, 
    IonToolbar, 
    IonTitle, 
    IonContent,
    IonButtons,
    IonButton,
    IonBackButton,
    IonIcon,
    IonList,
    //IonListHeader,
    IonItem,
    IonItemDivider,
    IonLabel,
    //IonCheckbox,
    IonSpinner,
    //IonNote,
    IonReorder,
    IonReorderGroup,
    //IonSelectOption,
    //IonMenu,
    //IonMenuButton,
    //IonPopover,
    IonText,
    
    IonItemSliding,
    IonItemOptions,
    IonItemOption,
    

    MInput,
    //MSelect,
    //MPassword,
  },
  setup(){

    const store = useStore();
    const route = useRoute();
    const router = useRouter();

    const updateId = ref(-1);

    const currentAction = ref("");

    /*
    const tasksByGroupId = computed(() => {
      console.log("taskByGroupId");
      const result = [];
      for (const group of store.state.taskGroups ){
        const groupResult = [];
        result[group.id] = [] as WeddingTask[];
      }

      for (const task of store.state.tasks){
        result[task.task_group.id].push(task);
      }
      return result;
    })
    */
    
    const groupId = ref(parseInt(route.params.todoCategoryId as string));
    const waitLoading = ref(true);

    const taskList = computed(() => {
      console.log("taskList");
      if ((! route.params.todoCategoryId) || waitLoading.value) {
        // no category id parameter returns all tasks.
      console.log("taskList all done");
        return [];//store.state.tasks;
      }
      
      const result = [];

      for (const task of store.state.tasks){
        if (task.task_group.id == groupId.value){
          result.push(task);
        }
      }
      console.log("taskList done");
      return result;
    })

    const categorySchema = yup.object({
      name: yup.string().required().label(t("Name")),
    });

    // Create a form context with the validation schema
    const { values, validate, resetForm } = useForm({
      validationSchema: categorySchema,
    });

    


    let reorderList: number[];
    function createTaskOrder(){
      console.log("createTaskOrder");
      // create a set of lists for the reorder operation
      reorderList = [];
        for (const task of taskList.value){
          reorderList.push(task.id);
        }
      console.log("createTaskOrder Done");
    }

    const taskGroup = ref<TaskGroup>();

    onIonViewDidEnter(()=>{
      currentAction.value = "";
      
      store.dispatch('getTasks').then(() => {
        createTaskOrder();
      });

      groupId.value = parseInt(route.params.todoCategoryId as string);
      if (! groupId.value){
        // routing here without groupId is a request to create a new group.
        currentAction.value = "create";
      } else {
        taskGroup.value = store.state.taskGroups.find((itm) => itm.id == groupId.value);
      }
      waitLoading.value = false;

    });

    async function iconClicked(id: number, currentStatus: number){
      const newItem = {
        id: id,
        // eslint-disable-next-line @typescript-eslint/camelcase
        task_status: currentStatus ? 0 : 1,
      }
      updateId.value = id;
      await store.dispatch('saveTask', newItem);
      updateId.value = -1;
    }

    function itemClicked(groupId: number, taskId: number){
      router.push('/tabs/todo/item/' + groupId + "/" + taskId )
      return;
    }

    function deleteClicked(id: number){
      store.dispatch('deleteTask', id);
    }

    function doReorder(evt: CustomEvent){
      const [el] = reorderList.splice(evt.detail.from, 1); 
      reorderList.splice(evt.detail.to, 0, el);
      evt.detail.complete();
    }

    async function openPopover(ev: Event) {
      const popover = await popoverController
        .create({
          component: Popover,
          cssClass: 'todo-popover',
          event: ev,
          translucent: true
        })
      popover.style.cssText = '--min-width: 250px;';
      await popover.present();

      const { data } = await popover.onDidDismiss();
      if (data == "edit"){
        resetForm();
        values.name = taskGroup.value!.name;
        currentAction.value = data;
      } else if (data == "create"){
        router.push('/tabs/todo/item/' + taskGroup.value!.id)
      } else if (data == "reorder"){
        if (reorderList.length < 2){
          showModal(t("Can't Reorder"), t("You need at least two tasks to be able to reorder the list."))
        } else {
          currentAction.value = data;
        }
      } else {
        currentAction.value = data;
      }
    }

    function dismissActions(){
      // performa any clean up / sync operations for each action
      switch (currentAction.value){
        case("reorder"):{
          const todoOrder: ItemOrder[] = [];
          let sortOrder = 10;
          for (const taskId of reorderList){
            // eslint-disable-next-line @typescript-eslint/camelcase
            todoOrder.push({id: taskId, sort_order: sortOrder});
            sortOrder += 10;
          }
          store.dispatch('reorderTasks', todoOrder);
        }
        break;

        case("create"):{
          if (! taskGroup.value){
            // route back if we did not end up creating a task.
            router.go(-1);
          }
        }
        break;
      }
      currentAction.value = "";
    }

    async function onSubmitForm(){
      const result = await validate();
      if (result.valid){
        let taskGroupResult: ApiError | TaskGroup;
        if (taskGroup.value){
          const args = {
            name: values.name,
            id: taskGroup.value.id,
          }
          taskGroupResult = await store.dispatch('updateTaskGroup', args);
        } else {
          taskGroupResult = await store.dispatch('createTaskGroup', values);
        }

        if (! isApiError(taskGroupResult)){
          taskGroup.value = taskGroupResult as TaskGroup;
          dismissActions();
          resetForm();
        }
      } 
    }

    return {

      //tasksByGroupId,
      taskList,
      currentAction,
      taskGroup,
      waitLoading,
      onSubmitForm,
      
      checkmark,
      addCircleOutline,
      create,
      close,
      shuffle,
      ellipsisVertical,
      trashOutline,
      chevronBack,

      router,
      iconClicked,
      itemClicked,
      deleteClicked,
      doReorder,
      openPopover,
      dismissActions,
      updateId,
      ellipseOutline,
      checkmarkCircleOutline,
      checkmarkCircle,
      store,
      apiEndpoints,
    }
  }
});
