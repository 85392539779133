import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_list = _resolveComponent("ion-list")!
  const _component_ion_content = _resolveComponent("ion-content")!

  return (_openBlock(), _createBlock(_component_ion_content, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_list, null, {
        default: _withCtx(() => [
          _createVNode(_component_ion_item, {
            lines: "none",
            button: "",
            onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.dismissPopover('edit')))
          }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_icon, {
                slot: "end",
                icon: _ctx.create
              }, null, 8, ["icon"]),
              _createVNode(_component_ion_label, null, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('Rename Category')), 1)
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_ion_item, {
            lines: "none",
            button: "",
            onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.dismissPopover('reorder')))
          }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_icon, {
                slot: "end",
                icon: _ctx.reorderFour
              }, null, 8, ["icon"]),
              _createVNode(_component_ion_label, null, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('Reorder Tasks')), 1)
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_ion_item, {
            lines: "none",
            button: "",
            onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.dismissPopover('create')))
          }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_icon, {
                slot: "end",
                icon: _ctx.addCircle
              }, null, 8, ["icon"]),
              _createVNode(_component_ion_label, null, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('Create Task')), 1)
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}