
import { 
  IonContent,
  IonList,
  IonItem,
  IonLabel,
  IonIcon,
  
  popoverController,
  } from '@ionic/vue';

import { 
  addCircle,
  create,
  reorderFour,
}  from 'ionicons/icons';

import { defineComponent } from 'vue';

export default defineComponent({
  name: 'Popover',
  components: { 
    IonContent,
    IonList,
    IonItem,
    IonLabel,
    IonIcon,
  },
  setup(){

    function dismissPopover(msg: string){
      popoverController.dismiss(msg);
    }

    return {
      addCircle,
      create,
      reorderFour,
      dismissPopover,
    }
  }
});
